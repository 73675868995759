<template>
  <div class="body-1">
    <v-card>
      <v-card-title>
        Agency List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="agencyList"
        :loading="isLoadingAgencies"
        :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
        :items-per-page="50"
        :search="search"
        :sort-by="['agencyId']"
        :sort-desc="[false]"
        class="elevation-1"
      >
        <template v-slot:item.agencyId="{item}">
          <router-link
            :to="{name: 'adminAgencyEdit',params: { agencyId: item.agencyId }}"
          >{{item.agencyId}}</router-link>
        </template>

        <template v-slot:no-data>
          <td>No data found</td>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :to="{ name: 'adminAgencyEdit', params: { agencyId: 'new'}}">Add New Agency</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

let headers = [
  {
    text: "Agency ID",
    value: "agencyId",
    sortable: true
  },
  {
    text: "Description",
    value: "description"
  },
  {
    text: "City",
    value: "agencyCity",
    sortable: true
  },
  {
    text: "County",
    value: "agencyCounty",
    sortable: true
  },
  {
    text: "State",
    value: "agencyState",
    sortable: true
  },
  {
    text: "Token",
    value: "token"
  }
];

export default {
  data() {
    return {
      search: "",
      headers: headers
    };
  },
  computed: mapState({
    isLoadingAgencies: state => state.admin.isLoadingAgencies,
    agencyList: state => state.admin.agencyList
  }),
  methods: {
    ...mapActions(["loadAdminAgencyList"])
  },
  created() {
    this.loadAdminAgencyList();
  }
};
</script>

